import React from 'react';

import Master from '../../components/Master/Master';

const NoMatch = () => {
    return (
        <Master>
            <h1>Page not found</h1>
        </Master>
    );
};

export default NoMatch;