import React, { useState, useEffect, useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { PageHeader, Layout, Table, Button, notification, Input, Modal, Badge } from 'antd';
import { CheckOutlined, SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import currencyFormatter from 'currency-formatter';

import useHttp from '../../hooks/http';
import Footer from '../../components/Footer/Footer';

const { Content } = Layout;

const Products = () => {

    const { isLoading, error, data, sendRequest } = useHttp();

    const [pagination, setPagination] = useState({});
    const [searchText, setSearchText] = useState('');
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [visibleDelete, setVisibleDelete] = useState(false);
    const [getData, setData] = useState([]);
    const [modalErrorVisible, setModalErrorVisible] = useState(false);
    const [modalErrorTitle, setModalErrorTitle] = useState('');
    const [modalErrorMessage, setModalErrorMessage] = useState('');
    const [modalErrorOkText, setModalErrorOkText] = useState('');

    const history = useHistory();

    const modalDelete = () => {
        setVisibleDelete(true)
    };

    const deleteData = e => {
        setVisibleDelete(false);
        for (let i = 0; i < selectedRowKeys.length; i++) {
            const graphqlQuery = {
                query: `
                    mutation DeleteProduct($id: ID!) {
                        deleteProduct(id: $id)
                    }
                `,
                variables: {
                    id: selectedRowKeys[i],
                }
            };
            sendRequest(
                graphqlQuery,
                'DELETE_PRODUCT',
            );
        }
    };

    const deleteCancel = e => {
        setVisibleDelete(false);
    };

    const changeError = e => {

        switch (modalErrorOkText) {
            case 'Realizar login como Administrador':
                history.replace('/');
                localStorage.clear();
                break;
            default:
                setTimeout(() => {
                    setPagination({});
                    setSearchText('');
                    setSelectedRowKeys([]);
                    setData([]);
                    fetch();
                }, 500);
        }
        setModalErrorVisible(false);
    };

    const changeErrorCancel = e => {
        setModalErrorVisible(false);
    };

    const onSelectToDelete = selectedRowKeys => {
        console.log('selectedRowKeys changed: ', selectedRowKeys);
        setSelectedRowKeys(selectedRowKeys);
    };

    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder='Pesquisar'
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm)}
                    style={{ width: 208, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => handleSearch(selectedKeys, confirm)}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ width: 110, marginRight: 8 }}
                >
                    Pesquisar
            </Button>
                <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Reiniciar
            </Button>
            </div>
        ),
        filterIcon: filtered => (
            <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
        render: text => (
            <Highlighter
                highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                searchWords={[searchText]}
                autoEscape
                textToHighlight={text.toString()}
            />
        ),
    });

    const handleSearch = (selectedKeys, confirm) => {
        confirm();
    };

    const handleReset = clearFilters => {
        clearFilters();
        setSearchText('');
    };

    const fetch = useCallback((params = {}) => {
        let sortField = params.sortField;
        if (sortField === undefined)
            sortField = 'updatedAt';
        let sortOrder = params.sortOrder;
        if (sortOrder === undefined)
            sortOrder = 'DESC';
        else if (sortOrder === 'ascend')
            sortOrder = 'ASC';
        else if (sortOrder === 'descend')
            sortOrder = 'DESC';
        const graphqlQuery = {
            query: `
              query Products($filter: String, $sortField: String, $sortOrder: String, $page: Int, $pageSize: Int) {
                products(filter: $filter, sortField: $sortField, sortOrder: $sortOrder, page: $page, pageSize: $pageSize){
                    products {
                      _id
                      title
                      price
                      totalCategories
                      totalKeywords
                      totalImages
                      isActivated
                      isFeatured
                      updatedAt
                    }
                    totalRecords
                  }
              }
            `,
            variables: {
                filter: searchText,
                sortField,
                sortOrder,
                page: params.page,
                pageSize: params.results
            }
        };

        sendRequest(
            graphqlQuery,
            'GET_PRODUCTS',
        );
    }, [searchText, sendRequest]);

    useEffect(() => {
        fetch();
    }, [fetch]);

    useEffect(() => {
        if (data) {
            if (data.products) {
                const _pagination = { ...pagination };
                _pagination.total = data.products.totalRecords;
                const initialData = data.products.products;
                const _data = initialData.map(item => {
                    return {
                        ...item,
                        isFeatured: item.isFeatured ?
                            <span>
                                <Badge status="success" />
                                Sim
                            </span>
                            :
                            <span>
                                <Badge status="error" />
                                Não
                            </span>,
                        isActivated: item.isActivated ?
                            <span>
                                <Badge status="success" />
                                Sim
                            </span>
                            :
                            <span>
                                <Badge status="error" />
                                Não
                            </span>,
                        createdAt: new Date(item.createdAt).toLocaleDateString('pt-BR'),
                        updatedAt: new Date(item.updatedAt).toLocaleDateString('pt-BR')
                    }
                });
                setPagination(_pagination);
                setData(_data);

            }
            if (data.deleteProduct) {
                if (data.deleteProduct === true) {
                    fetch();
                    notification.open({
                        message: 'Registros excluídos com sucesso!',
                        icon: <CheckOutlined style={{ color: '#008000' }} />,
                        duration: 2
                    });
                    setTimeout(() => {
                        setSelectedRowKeys([]);
                    }, 500);
                }
                else {
                    setModalErrorVisible(true);
                    setModalErrorTitle('Erro ao excluir registros(s)!');
                    setModalErrorMessage('Houve um erro desconhecido ao excluir um ou mais registros.');
                    setModalErrorOkText('Ok');
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, fetch]);

    useEffect(() => {
        if (error) {
            try {
                if (error[0].status) {
                    if (error[0].status === 404 || error[0].status === 401) {
                        history.replace('/');
                        localStorage.clear();
                        setModalErrorVisible(true);
                        setModalErrorTitle('Não Autorizado!');
                        setModalErrorMessage('Você não tem autorização para acessar esta área.');
                        setModalErrorOkText('Realizar login como Administrador');
                    }
                    else {
                        setModalErrorVisible(true);
                        setModalErrorTitle('Erro');
                        setModalErrorMessage(error[0].message);
                        setModalErrorOkText('Ok');
                    }
                }
            }
            catch {
                setModalErrorVisible(true);
                setModalErrorTitle('Erro');
                setModalErrorMessage('Erro ao acessar servidor, clique em OK para tentar novamente.');
                setModalErrorOkText('Ok');
            }
        }
    }, [error, history]);

    const handleTableChange = (_pagination, filters, sorter) => {
        const pager = { pagination };
        pager.current = _pagination.current;
        setPagination(pager);
        if (!filters.title) {
            fetch({
                results: _pagination.pageSize,
                page: _pagination.current,
                sortField: sorter.field,
                sortOrder: sorter.order,
                ...filters
            });
        }
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectToDelete,
        hideDefaultSelections: true
    };
    const hasSelected = selectedRowKeys.length > 0;
    const columns = [
        {
            title: 'Ações',
            dataIndex: '_id',
            key: '_id',
            render: text => <Link to={'/products/' + text} key={text}>Editar</Link>,
            fixed: 'left',
            width: 80
        },
        {
            title: 'Título',
            dataIndex: 'title',
            key: 'title',
            sorter: true,
            ...getColumnSearchProps('title'),
        },
        {
            title: 'Categorias',
            dataIndex: 'totalCategories',
            key: 'totalCategories',
            width: 100
        },
        {
            title: 'Palavras-chave',
            dataIndex: 'totalKeywords',
            key: 'totalKeywords',
            width: 100
        },
        {
            title: 'Imagens sec.',
            dataIndex: 'totalImages',
            key: 'totalImages',
            width: 100
        },
        {
            title: 'Preço',
            dataIndex: 'price',
            key: 'price',
            render: text => currencyFormatter.format(text, { locale: 'pt-BR' }),
            sorter: true,
            width: 100,
        },
        {
            title: 'Destaque',
            dataIndex: 'isFeatured',
            key: 'isFeatured',
            sorter: true,
            width: 90
        },
        {
            title: 'Ativado',
            dataIndex: 'isActivated',
            key: 'isActivated',
            sorter: true,
            width: 90,
        },
        {
            title: 'Alteração',
            dataIndex: 'updatedAt',
            defaultSortOrder: 'descend',
            key: 'updatedAt',
            sorter: true,
            width: 120
        }
    ];

    return (
        <Layout>
            <PageHeader title="Produtos"></PageHeader>
            <Content style={{ margin: '0 16px' }}>
                <div>
                    <div style={{ marginBottom: 16 }}>
                        <Button type="primary" onClick={modalDelete} disabled={!hasSelected} loading={isLoading}>
                            Excluir
                            </Button>
                        <Modal
                            title="Exclusão de registro(s)"
                            visible={visibleDelete}
                            onOk={deleteData}
                            onCancel={deleteCancel}
                            okText='Sim'
                            cancelText='Não'
                        >
                            <p>Confirma a exclusão do(s) registro(s) selecionado(s)?</p>
                        </Modal>
                        <Modal
                            title={modalErrorTitle}
                            visible={modalErrorVisible}
                            onOk={changeError}
                            onCancel={changeErrorCancel}
                            okText={modalErrorOkText}
                        >
                            <p>{modalErrorMessage}</p>
                        </Modal>
                        <span style={{ marginLeft: 8 }}>
                            {hasSelected ? `${selectedRowKeys.length} registro(s) selecionado(s)` : ''}
                        </span>
                    </div>
                    <Table rowSelection={rowSelection} columns={columns} dataSource={getData} pagination={pagination} rowKey={record => record._id} loading={isLoading} onChange={handleTableChange} scroll={{ x: 1300 }} />
                    <div style={{ marginTop: 16 }}>
                        <Button type="primary" onClick={() => history.replace('/products/new')}>
                            Novo registro
                    </Button>
                    </div>
                </div>
            </Content>
            <Footer />
        </Layout>
    );
};

export default Products;