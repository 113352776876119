import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import {
    PageHeader, Layout, Form, Input, Button,
    notification, Alert, Upload, Switch, Card,
    message, Col, Row, Tabs
} from 'antd';
import {
    CheckOutlined, LockOutlined, InboxOutlined, BarcodeOutlined,
} from '@ant-design/icons';
import TextArea from 'antd/lib/input/TextArea';

import useHttp from '../../../hooks/http';
import Footer from '../../../components/Footer/Footer';

const { Content } = Layout;
const { Dragger } = Upload;
const { TabPane } = Tabs;

const Post = () => {

    const { isLoading, error, data, sendRequest } = useHttp();

    const match = useRouteMatch('/posts/:id');
    const history = useHistory();
    const [id] = useState(match.params.id);
    const [validationErrors, setValidationErrors] = useState('');
    const [title, setTitle] = useState('');
    const [isActivated, setIsActivated] = useState(true);
    const [isFeatured, setIsFeatured] = useState(true);
    const [mainImage, setMainImage] = useState('');

    const propsUploadImage = {
        name: 'image',
        action: `${process.env.REACT_APP_API_URL}/image`,
        multiple: false,
        method: 'PUT',
        onChange(info) {
            if (info.file.status === 'done') {
                message.success(`${info.file.name} carregado com sucesso!`);

                setMainImage(info.fileList[info.fileList.length - 1].response.file);
                let uploadedFiles = info.fileList[info.fileList.length - 1].response.file;
                console.log('Uploaded Files', uploadedFiles);
                const graphqlQuery = {
                    query: `
                    mutation StoragePostImage ($postId: ID!, $originalFile: String!){
                        storagePostImage (id: $postId, 
                            storagePostImageInput:{
                                originalFile: $originalFile
                            }
                        )
                        {
                            image
                        }
                    }
                    `,
                    variables: {
                        postId: id,
                        originalFile: info.fileList[info.fileList.length - 1].response.file,
                    }
                };
                sendRequest(
                    graphqlQuery,
                    'UPDATE_IMAGE',
                );
            }
            else if (info.file.status === 'error') {
                message.error(`Erro ao carregar arquivo ${info.file.name}.`);
            }
        },
    };

    const formRef = useRef();

    const onIsActivatedChange = checked => {
        setIsActivated(checked);
    };

    const onIsFeaturedChange = checked => {
        setIsFeatured(checked);
    };

    const handleSubmit = values => {
        setValidationErrors('');
        if (id !== 'new') {
            // UPDATE
            const graphqlQuery = {
                query: `
                                mutation UpdatePost ($postId: ID!, $title: String!, 
                                    $description: String!, $keywords: String!, $details: String!, 
                                    $isActivated: Boolean!,
                                    $isFeatured: Boolean!){
                                    updatePost(id: $postId,
                                        postInput:{
                                            title: $title
                                            description: $description
                                            keywords: $keywords
                                            details: $details
                                            isActivated: $isActivated
                                            isFeatured: $isFeatured
                                        }
                                    )
                                    {
                                        _id
                                        title
                                        description
                                        keywords
                                        details
                                        isActivated
                                        isFeatured
                                    }
                                }
                                `,
                variables: {
                    postId: id,
                    title: values.title,
                    description: values.description,
                    keywords: values.keywords,
                    details: values.details,
                    isActivated: isActivated,
                    isFeatured: isFeatured
                }
            };
            sendRequest(
                graphqlQuery,
                'UPDATE_POST',
            );
        }
        else {
            const graphqlQuery = {
                query: `
                    mutation createPost (
                        $title: String!, $description: String!, $keywords: String!, 
                        $details: String!, 
                        $isActivated: Boolean!,
                        $isFeatured: Boolean!){
                        createPost(
                            postInput:{
                                title: $title
                                description: $description
                                keywords: $keywords
                                details: $details
                                isActivated: $isActivated
                                isFeatured: $isFeatured
                            }
                        )
                        {
                            _id
                            title
                            description
                            keywords
                            details
                            isActivated
                            isFeatured
                        }
                    }
                `,
                variables: {
                    postId: id,
                    title: values.title,
                    description: values.description,
                    keywords: values.keywords,
                    details: values.details,
                    isActivated: isActivated,
                    isFeatured: isFeatured
                }
            };
            sendRequest(
                graphqlQuery,
                'CREATE_POST',
            );
        }
    };

    const onFillForm = args => {
        setIsActivated(args.isActivated);
        setIsFeatured(args.isFeatured);
        formRef.current.setFieldsValue({
            title: args.title,
            description: args.description,
            keywords: args.keywords,
            details: args.details
        });
    };

    const getPost = useCallback(() => {
        const graphqlQuery = {
            query: `
          query Post ($postId: ID!){
            post(id: $postId){
                _id
                title
                description
                keywords
                details
                image
                imageThumbnail
                isActivated
                isFeatured
            }
          }
        `,
            variables: {
                postId: id
            }
        };

        sendRequest(
            graphqlQuery,
            'GET_POST',
        );
    }, [id, sendRequest]);

    useEffect(() => {
        if (id !== 'new') {
            getPost();
        }

        getPost();

    }, [id, getPost, sendRequest]);

    useEffect(() => {
        if (data) {
            if (data.post) {
                setTitle(data.post.title);
                const args = [];
                args.title = data.post.title;
                args.description = data.post.description;
                args.keywords = data.post.keywords;
                args.details = data.post.details;
                setMainImage(data.post.imageThumbnail);
                args.isActivated = data.post.isActivated;
                args.isFeatured = data.post.isFeatured;
                onFillForm(args);
            }
            if (data.updatePost) {
                notification.open({
                    message: 'Registro atualizado com sucesso',
                    icon: <CheckOutlined style={{ color: '#008000' }} />,
                    duration: 2
                });
                history.replace('/posts/');
            }
            if (data.createPost) {
                notification.open({
                    message: 'Registro inserido com sucesso',
                    icon: <CheckOutlined style={{ color: '#008000' }} />,
                    duration: 2
                });
                history.replace('/posts/');
            }
            if (data.storagePostImage) {
                getPost();
                notification.open({
                    message: `Imagem principal atualizada com sucesso!`,
                    icon: <CheckOutlined style={{ color: '#008000' }} />,
                    duration: 4
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, getPost, history]);

    useEffect(() => {
        if (error) {
            if (error[0].status === 401) {
                history.replace('/');
                localStorage.clear();
                notification.open({
                    message: 'Não Autorizado!',
                    description:
                        'Você não tem autorização para acessar esta área. Clique aqui para realizar Login',
                    icon: <LockOutlined style={{ color: '#B22222' }} />,
                    onClick: () => {
                        history.replace('/');
                        localStorage.clear();
                    },
                    duration: 1
                });
            }
            if (error[0].status === 422) {
                const valErrors = [];
                for (let i = 0; i < error[0].data.length; i++) {
                    let message = error[0].data[i].message;
                    if (message.includes('Description must be 120 to 160 characters.'))
                        message = 'Descrição deve ter entre 120 e 160 caracteres.';
                    valErrors.push(message);
                }
                if (validationErrors === undefined) {
                    setValidationErrors(valErrors + '\n');
                } else {
                    setValidationErrors(validationErrors + valErrors + '\n');
                }
            }
            if (error[0].status === 11000) {
                let err;
                if (error[0].message.includes('duplicate key error collection') === true) {
                    err = 'Título ou Descrição duplicados.';
                }
                else {
                    err = error[0].message;
                }

                if (validationErrors === undefined) {
                    setValidationErrors(err + '\n');
                } else {
                    setValidationErrors(validationErrors + err + '\n');
                }
            }
            if (error[0].status === 404) {
                let err;
                if (error[0].message.includes('No post found!') === true) {
                    err = 'Postagem não encontrada.';
                }
                else {
                    err = error[0].message;
                }

                if (validationErrors === undefined) {
                    setValidationErrors(err + '\n');
                } else {
                    setValidationErrors(validationErrors + err + '\n');
                }
            }
            if (error[0].status === 500) {
                if (id !== 'new') {
                    if (error[0].message.includes('Cast to ObjectId failed for value ') === true) {
                        notification.open({
                            message: 'Postagem não encontrada',
                            description:
                                'Você foi direcionado para a página de Postagens',
                            icon: <BarcodeOutlined style={{ color: '#B22222' }} />,
                            onClick: () => {
                                history.replace('/posts/');
                            },
                            duration: 3
                        });
                        history.replace('/posts/');
                    }
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error]);

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    };

    const tailFormItemLayout = {
        wrapperCol: {
            xs: {
                span: 24,
                offset: 0,
            },
            sm: {
                span: 16,
                offset: 8,
            },
        },
    };

    let image = null;
    if (id !== 'new') {
        image = (
            <div>
                <div style={{ textAlign: 'center' }}>
                    <h2>Imagem principal</h2>
                    <Row gutter={16}>
                        <Col span={8}></Col>
                        <Col span={8}>
                            <Card
                                hoverable
                                cover={<img title={title} alt={title} src={mainImage} />}
                            >
                                <Dragger {...propsUploadImage}>
                                    <p className="ant-upload-drag-icon">
                                        <InboxOutlined />
                                    </p>
                                </Dragger>
                            </Card>
                        </Col>
                        <Col span={8}></Col>
                    </Row>
                </div>
            </div>
        );
    }

    return (
        <Layout>
            <PageHeader
                ghost={false}
                onBack={() => history.replace('/posts/')}
                title="Postagens"
                subTitle={title === null ? 'Novo registro' : title}></PageHeader>
            <Content style={{ marginTop: '25px', marginLeft: '20px', marginRight: '20px' }}>
                <Tabs defaultActiveKey="1">
                    <TabPane tab="Informações gerais" key="1">
                        <Form {...formItemLayout} onFinish={handleSubmit} ref={formRef}>
                            <Form.Item label="Título" name="title"
                                rules={[{
                                    required: true,
                                    message: 'Favor preencher o Título!'
                                }]}>
                                <Input disabled={id === 'new' ? false : true} />
                            </Form.Item>
                            <Form.Item label="Descrição" name="description"
                                rules={[{
                                    required: true,
                                    message: 'Favor preencher a Descrição!'
                                }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label="Palavras-chave" name="keywords"
                                rules={[{
                                    required: true,
                                    message: 'Favor preencher as Palavras-chave!'
                                }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label="Detalhes" name="details"
                                rules={[{
                                    required: true,
                                    message: 'Favor preencher os Detalhes!'
                                }]}>
                                <TextArea rows={5} />
                            </Form.Item>
                            <Form.Item label="Ativado">
                                <Switch checked={isActivated} onChange={onIsActivatedChange} />
                            </Form.Item>
                            <Form.Item label="Destaque">
                                <Switch checked={isFeatured} onChange={onIsFeaturedChange} />
                            </Form.Item>
                            {
                                validationErrors !== '' ?
                                    <Form.Item {...tailFormItemLayout}>
                                        <Alert
                                            message="Verifique os seguintes erros"
                                            description={validationErrors}
                                            type="error"
                                            showIcon
                                        />
                                    </Form.Item> : null
                            }
                            <Form.Item {...tailFormItemLayout}>
                                <Button type="primary" htmlType="submit" loading={isLoading}>
                                    Salvar
                            </Button>
                            </Form.Item>
                        </Form>
                    </TabPane>
                    <TabPane tab="Imagem" key="2" disabled={id === 'new' ? true : false}>
                        <Form {...formItemLayout}>
                            {image}
                        </Form>
                    </TabPane>
                </Tabs>
            </Content>
            <Footer />
        </Layout >
    );
}

export default Post;